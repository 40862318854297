import { CircularProgress } from "@material-ui/core"
import React from "react"
import styled from "styled-components"
import { device } from "../../constants/breakpoints"

const Container = styled.div`
  display: flex;
  position: absolute;
  flex: 1;
  height: 100%;
  width: 100%;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;

  @media ${device.tablet} {
    padding-top: 100px;
  }
`

export default function LoadingView() {
  return (
    <Container>
      <CircularProgress color={"#337ab7"} />
    </Container>
  )
}
